import React, { useState } from "react"

const Form = () => {
  const [status, setStatus] = useState('');

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };

  return <div className="form-wrapper">
        <form action="https://formspree.io/f/myybwdov" onSubmit={submitForm} method="POST">
            <h2>Form</h2>
            <div className="form-group">
            <input type="text" placeholder="name" name="name" className="form-control"/>
            <input type="email" placeholder="email" name="email" className="form-control"/>
            <input type="tel" placeholder="Telephone" name="telephone" className="form-control"/>
            <textarea name="message" placeholder="message" className="form-control" rows="5"></textarea>
            {status === "SUCCESS" ? <p>Thanks!</p> : <button type="submit" className="submit-btn btn">Submit</button>}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
            </div>
        </form>
    </div>
}

export default Form;