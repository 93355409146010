import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Teams from "../components/Teams"
import Form from "../components/Form"
import SEO from "../components/SEO"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone, faMapPin } from '@fortawesome/free-solid-svg-icons'


const contact = ({
    data: {
      allContentfulTeam: { nodes: team }
    },
  }) => {

  return <Layout>
    <SEO title="Contact" description="Contact High Rock Capital."/>
    <section className="contact-page section-padding">
      <article className="section-center-narrow">
        <h1>Contact us</h1>
        <div className="personal-contact">
          <Teams team={team} list contact/>
        </div>
        <Form/>
          <div className="office-wrapper">
            <h2><span aria-hidden="true"><FontAwesomeIcon icon={faBuilding}/> </span>Office</h2>
              <p>
              <FontAwesomeIcon icon={faMapPin}/>1 Toronto St., Suite 210, PO Box 4</p>
              <p className="office-contact-padding">Toronto, Ont.</p>
              <p className="office-contact-padding">M5C 2V6</p>
              <a href="tel:1-866-697-6928">
              <FontAwesomeIcon icon={faPhone}/> 1-866-697-6928
              </a>
          </div>
      </article>
    </section>
  </Layout>
}

export const query = graphql`
{
  allContentfulTeam(sort: {fields: id, order: ASC}) {
    nodes {
      id
      name
      position
      telephone
      email
    }
  }
}
`

export default contact